import { Component, OnInit } from '@angular/core';
import { MenuList } from 'src/app/config/menu.config';

@Component({
  selector: 'clz-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss']
})
export class MenuMobileComponent implements OnInit {
  menuList;
  showMenu = false;

  constructor() { }

  ngOnInit() {
    this.menuList = MenuList
  }

  switchMenu() {
    this.showMenu = !this.showMenu;
  }

}
