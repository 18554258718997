import { Component, OnInit } from '@angular/core';
import { MenuConfig } from 'src/app/config/menu.config';
import { LoaderService } from '../../services/loader.service';
import { ThemeService } from '../../services/theme.service';
import { HeaderStyle } from '../../models/theme.model';

@Component({
  selector: 'clz-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  Headerconfig;
  logo;

  hasUserSystem = false;

  isTop = true;

  get style(): HeaderStyle {
    return this.themeService.headerStyle;
  }

  oldStyle: HeaderStyle;

  constructor(
    private loaderService: LoaderService,
    private themeService: ThemeService
  ) { }

  ngOnInit() {
    if (this.loaderService.config.sso_url == '') this.hasUserSystem = false;
    this.logo = this.loaderService.config.logo_top;
    this.Headerconfig = MenuConfig;
    addEventListener("scroll", (e: any) => {
      let scrollTop = document.documentElement.scrollTop || document.scrollingElement.scrollTop;

      if (scrollTop > 1) {
        this.themeService.setHeaderStyle({ color: 'rgba(0, 0, 0, 0.75)' })
      } else {
        this.themeService.setDefaultHeaderStyle()
      }
    });
  }

}
