<header class="container-fluid" [ngStyle]="{'background-color': '#fcfcfc'}"
  [ngClass]="{'fixed': style.position== 'fixed','cover':style.position=='cover'}">
  <div class="header-box container">
    <div class="left-box">
      <div class="logo" [routerLink]="['/']">
        <img class="animated faster fadeIn" src="{{logo}}">
      </div>
    </div>
    <div class="right-box">
      <clz-menu-mobile class="d-md-none"></clz-menu-mobile>
      <clz-menu class="d-none d-md-block"></clz-menu>
      <!-- <clz-user class="d-none d-md-block"></clz-user> -->
    </div>
  </div>
</header>