import { Injectable } from '@angular/core';
import { HeaderStyle } from 'src/app/core/models/theme.model'

@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    headerStyle: HeaderStyle = {
        color: '#C91517', // black,transparent,translucent
        position: 'fixed'
    }

    headerStyle_default: HeaderStyle = {
        color: '#C91517',
        position: 'fixed'
    }

    constructor(
    ) { }

    initHeaderStyle(style: HeaderStyle) {
        if (typeof style.color != 'undefined') {
            this.headerStyle.color = style.color
            this.headerStyle_default.color = style.color
        }
        if (typeof style.position != 'undefined') {
            this.headerStyle.position = style.position
            this.headerStyle_default.position = style.position
        }
    }

    setHeaderStyle(style: HeaderStyle) {
        if (typeof style.color != 'undefined')
            this.headerStyle.color = style.color
        if (typeof style.position != 'undefined')
            this.headerStyle.position = style.position
    }

    setDefaultHeaderStyle() {
        this.headerStyle.position = this.headerStyle_default.position
        this.headerStyle.color = this.headerStyle_default.color
    }

}
