<footer>
  <div class="container">
    <div class="row row1">
      <div class="col-md-4 left-box">
        <div class="logo" [routerLink]="['/']">
          <img class="animated faster fadeIn" src="/assets/img/logo-white.png">
        </div>
        <div class="text">{{footer_about_text}}</div>

        <div><strong>{{brand}}</strong> - © Copyright 2021. All Rights Reserved. <span>{{footer_text}}</span></div>
        <div><a class="beian" href="https://beian.miit.gov.cn/">{{footer_text2}}</a></div>
        
      </div>
      <div class="col-md-8 right-box">
        <div class="row">
          <div class="col-md-6">
            <div class="mail-box">
              <div class="item">
                <div class="title">技术支持</div>support@openjumper.com
              </div>
              <div class="item">
                <div class="title">购买咨询</div>sales@openjumper.com
              </div>
              <div class="item">
                <div class="title">社区支持</div><a href="https://www.arduino.cn/forum-115-1.html">Arduino中文社区</a>
              </div>
              <div class="item">
                <div class="title">联系电话</div>028-85586651
              </div>
            </div>
          </div>
          <!-- <div class="col-md-4">
            <div class="wechat-card">
              <div>email: edu@diandeng.tech</div>
              教育合作
            </div>
          </div> -->
          <!-- <div class="col-md-3">
            <div class="wechat-card">
              <img src="assets/img/qrcode.jpg" alt="">
              微信公众号
            </div>
          </div> -->
          <div class="col-md-3">
            <div class="wechat-card">
              <img src="assets/img/qqqun.jpg" alt="">
              开发者QQ群
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row row2">
      <div class="col-md-5">
        
      </div>
      <div class="col-md-7">
      </div>
    </div> -->
  </div>
</footer>