export const AppConfig = {
    brand_text: 'OpenJumper',
    logo_top: 'assets/img/logo-red.png',
    logo_bottom: 'assets/img/logo-red.png',
    favicon:'assets/img/favicon.png',
    footer_text: '',
    footer_text2: '蜀ICP备12004613号-7',
    footer_about_text: '成都智能盒子科技有限公司，专业的开源硬件提供商',
    domain: 'https://openjumper.com',
    sso_url: '',
    admin_url: '',
}