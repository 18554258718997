<div class="menu-box">
  <ng-container *ngFor="let item of menuList">
    <div class="item-box" [ngClass]="{'icon': item.icon}">
      <!-- <div class="line"></div> -->
      <!-- 无子菜单 -->
      <ng-container *ngIf="!item.submenu">
        <a class="item" *ngIf="item.page && !item.anchor" [routerLink]="[item.page]" routerLinkActive="active">
          {{item.title}}
        </a>
        
        <a class="item" *ngIf="item.title && item.link" href="{{item.link}}" target="_blank">
          {{item.title}}
        </a>
        <a class="item" *ngIf="item.icon && item.link" href="{{item.link}}" target="_blank">
          <i class="{{item.icon}}"></i>
        </a>
        <a class="item showimg" *ngIf="item.icon && item.img">
          <i class="{{item.icon}}"></i>
          <div class="image">
            <img src="{{item.img}}">
            <div class="text">点灯物联</div>
          </div>
        </a>
        <a class="item" *ngIf="item.anchor" [routerLink]="[item.page]" fragment="{{item.anchor}}">
          {{item.title}}
        </a>
      </ng-container>
      <!-- 有子菜单 -->
      <ng-container *ngIf="item.submenu">
        <a class="item has-subitem">
          {{item.title}}
        </a>
        <!-- 子菜单 -->
        <div class="drapdown-box">
          <div class="submenu-box">
            <ng-container *ngFor="let subitem of item.submenu">
              <div class="item subitem">
                <a *ngIf="subitem.page" [routerLink]="[subitem.page]">
                  {{subitem.title}}
                  <p>{{subitem.text}}</p>
                </a>
                <a *ngIf="subitem.link" href="{{subitem.link}}" target="_blank">
                  {{subitem.title}}
                  <p>{{subitem.title}}</p>
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <div class="active-line">
        <div class="line"></div>
      </div>
    </div>
  </ng-container>
</div>