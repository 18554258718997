<div class="menu-btn" (click)="switchMenu()">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
</div>
<div class="menu-box" *ngIf="showMenu">
    <ng-container *ngFor="let item of menuList">
        <div class="item-box">
            <!-- <div class="line"></div> -->
            <!-- 无子菜单 -->
            <ng-container *ngIf="!item.submenu">
                <a class="item" *ngIf="item.page && !item.anchor" [routerLink]="[item.page]" routerLinkActive="active">
                    {{item.title}}
                </a>
                <a class="item" *ngIf="item.link" href="{{item.link}}" target="_blank">
                    {{item.title}}
                </a>
                <a class="item" *ngIf="item.anchor" [routerLink]="[item.page]" fragment="{{item.anchor}}">
                    {{item.title}}
                </a>
            </ng-container>
            <!-- 有子菜单 -->
            <ng-container *ngIf="item.submenu">
                <a class="item has-subitem">
                    {{item.title}}
                </a>
                <!-- 子菜单 -->
                <div class="drapdown-box">
                    <div class="submenu-box">
                        <ng-container *ngFor="let subitem of item.submenu">
                            <div class="item subitem">
                                <a *ngIf="subitem.page" [routerLink]="[subitem.page]">
                                    {{subitem.title}}
                                </a>
                                <a *ngIf="subitem.link" href="{{subitem.link}}" target="_blank">
                                    {{subitem.title}}
                                </a>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>