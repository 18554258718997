import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/config/app.config';
import { MenuList } from 'src/app/config/menu.config';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    config;
    menu;

    constructor(
    ) { }

    load() {
        this.config = AppConfig
        this.menu = MenuList
        // this.change()
    }

    change() {
        document.title = this.config.brand_text
        let link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = this.config.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);
    }


}
