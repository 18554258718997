import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'clz-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  brand;
  footer_text;
  footer_text2;
  footer_about_text;
  logo;

  constructor(
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.brand = this.loaderService.config.brand_text;
    this.footer_text = this.loaderService.config.footer_text;
    this.footer_text2 = this.loaderService.config.footer_text2;
    this.footer_about_text = this.loaderService.config.footer_about_text;
    this.logo = this.loaderService.config.logo_top;
  }

}
