import { Component } from '@angular/core';
import { LoaderService } from './core/services/loader.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'blinker-website';

  constructor(
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (typeof params.file != 'undefined') {
        this.router.navigate(['/doc', params.file + '.md'])
      }
    })
    this.loaderService.load();
  }
}
