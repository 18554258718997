<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
<div class="page-box">
  <div class="box1">
    <div class="banner1">
      <div class="bg"><img src="assets/img/51.png" alt="背景图" /></div>
      <div class="block-box">
        <div class="left">
          <div class="img50"><img src="assets/img/50.png" alt="科技创造未来" /></div>
        </div>
        <div class="right">
          <div class="top">
            <div class="img52"><a href="https://www.openjumper.com/doc" target="_blank"><img src="assets/img/52.png"
                  alt="文档" /></a></div>
          </div>
          <div class="bottom">
            <div class="img53">
              <a href="https://shop555818949.taobao.com" target="_blank"><img src="assets/img/53.png" alt="商店" /></a>
            </div>
            <div class="img54">
              <a href="https://arduino.me/home" target="_blank"><img src="assets/img/54.png"
                  alt="社区" /></a>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>

  <div class="box2">
    <div class="title">
      <h2 class="biaoti"><span>主要服务</span></h2>
      <h2 class="english">MAIN SERVICE</h2>
    </div>
    <br>
    <br>
    <div class="row">
      <img src="assets/img/71.png" alt="服务" />
    </div>
  </div>

  <div class="box3">
    <div class="title">
      <h2 class="biaoti"><span>热销产品</span></h2>
      <h2 class="english">POPULAR PRODUCTS</h2>
    </div>
    <div class="item-list">
      <a href=" https://item.taobao.com/item.htm?id=585091448121" target="_blank" class="item">
        <img src="assets/img/56.png" alt="程序设计基础套件" />
      </a>
      <a href=" https://item.taobao.com/item.htm?id=568753707693" target="_blank" class="item">
        <img src="assets/img/57.png" alt="图形化编程套件" />
      </a>
      <a href=" https://item.taobao.com/item.htm?id=568753707693" target="_blank" class="item">
        <img src="assets/img/58.png" alt="wifiduino" />
      </a>
      <a href=" https://item.taobao.com/item.htm?id=643400454215" target="_blank" class="item">
        <img src="assets/img/59.png" alt="多功能小车" />
      </a>
    </div>
    <div class="item-list item-list2">
      <a href=" https://item.taobao.com/item.htm?id=586553871202" target="_blank" class="item item2">
        <img src="assets/img/60.png" alt="防反接传感器扩展板" />
      </a>
      <a href=" https://item.taobao.com/item.htm?id=537350400463" target="_blank" class="item item2">
        <img src="assets/img/61.png" alt="传感器扩展板" />
      </a>
      <a href=" https://item.taobao.com/item.htm?id=586318430755" target="_blank" class="item item2">
        <img src="assets/img/62.png" alt="microbit扩展板" />
      </a>
      <a href=" https://item.taobao.com/item.htm?id=568753707693" target="_blank" class="item item2">
        <img src="assets/img/63.png" alt="zduino uno" />
      </a>
      <a href=" https://item.taobao.com/item.htm?id=538008420639" target="_blank" class="item item2">
        <img src="assets/img/64.png" alt="kit套件" />
      </a>
      <a href=" https://item.taobao.com/item.htm?id=571721414420" target="_blank" class="item item2">
        <img src="assets/img/65.png" alt="四驱小车" />
      </a>
      <a href=" https://item.taobao.com/item.htm?id=537118397795" target="_blank" class="item item2">
        <img src="assets/img/66.png" alt="两驱小车" />
      </a>
      <a href=" https://item.taobao.com/item.htm?id=592030973220" target="_blank" class="item item2">
        <img src="assets/img/67.png" alt="wifiduino小车" />
      </a>
    </div>
  </div>
  <br>

  <div class="box5">
    <br>
    <br>
    <br>
    <div class="title">
      <h2 class="biaoti"><span>厂区展示</span></h2>
      <h2 class="english">FACTORY SHOW</h2>
    </div>
    <br>
    <br>
    <div class="factory">
      <img src="assets/img/70.png" alt="工厂" />
      <img src="assets/img/69.png" alt="模块" />
    </div>
  </div>
</div>