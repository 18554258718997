export const MenuConfig = {
    isFixed: true,
    isCover: true
}

export const MenuList = [
    {
        title: '首页',
        page: '/home'
    },
    {
        title: '文档',
        page: '/doc',
    },
    // {
    //     title: '资源下载',
    //     page: '/dev',
    // },
    {
        title: '商店',
        link: 'https://shop555818949.taobao.com/',
    },
    {
        title: '物联网',
        link: 'https://arduino.me/a/ble-control'
    },
    {
        title: '软件下载',
        link: 'https://arduino.me/download'
    },
    {
        title: '开发者社区',
        link: 'https://www.arduino.me'
    }
]

export const UserMenuList = [
    { title: '开发运维管理', action: 'goto admin' },
    { title: '登出', action: 'logout' },
]