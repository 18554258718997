import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'clz-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  menuList;
  show = false;

  constructor(
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.menuList = this.loaderService.menu
  }

  toggleCollapse() {
    this.show = !this.show;
  }

  goto(anchor) {
    window.location.hash = anchor;
  }

}
